var menu = $('#menu');
var menuOverlay = $('#menu-overlay');

$('body').click(function() {
    menu.hide();
    // menuOverlay.hide();
});

$('#my-account').click(function(e) {
    e.stopPropagation();
    menu.toggle();
    menuOverlay.toggle();
});

$(function() {
    $(window).on('show.bs.modal', function(e) {
        menuOverlay.show();
    });

    $(window).on('hide.bs.modal', function(e) {
        menuOverlay.hide();
    });
});